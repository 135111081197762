
/* Media query for smaller screens */
@media screen and (max-width: 768px) {

    .App-header {
        width:100vw;
    }

    .App-content {
        width:100vw;
        display:flex;
        flex-direction: column;
        align-items: center;
        overflow-x:hidden;
        min-height:fit-content;
    }

    a {
        font-size: large;
    }

    button {
        width:initial;
        margin:10vw;
        height:4em;
    }

    p {
        font-size:.89em;
    }

    .button-container {
        justify-content: center;

    }

    .content-container {
        align-items: center;
        flex-direction: column;
    }

    .App-page#about {
        max-height:fit-content;
    }

    .content-container#about-content {
        flex-direction: column-reverse;
        height: fit-content;
        justify-content: flex-end;
    }

    h2.title-black {
        margin-bottom: 0;
        margin-top: 1vh;
        scroll-margin-top: 14rem;
    }

    h2.section-title-black {
    color: white;
    /* background-color: black; */
    font-size: .75em;
    height: fit-content;
    padding: 0.5em;
    background-color:rgba(168, 34, 94, 1);
    }

    .hamburger {
        display:initial;
    }

    #nav-bar {
        flex-direction:column;
        width:100vw;
        display: flex;
        justify-content: center;
        align-items: left;
    }

    .img-container {
        width:100%;
        overflow:hidden;
        height: 50%;
    }

    .about-text {
        margin: 0;
    }

    #message-container-about {
        height: 75%;
    }

    .main-img {
        width:100%;
    }

    .img-container#about {
        width:100%;
        align-items:baseline;
        
        
    }

    .main-img#about-img {
        height: auto;
        width:100%;
    }

    #img-container-home {
        height:55%;
    }

    .main-img#home-img {
        width:100%;
    }

    .msg-container {
        height:50%;
    }
    
    #nav-bar {
        display: none;
        padding-left: 0;
    }
    
    .navigation-bar[expanded="true"] #nav-bar {
        display:flex;
        width:unset;
    }
    .navigation-bar {
        width:100vw;
        padding-right:initial;
        margin-right:initial;

    }

    .navigation-bar[expanded="true"] {
        height: fit-content;
    }

    .navigation-bar-container {
        min-height:10vh;
        opacity: 80%;
    }

    .messsage-container {
        width:100%;
        height:50%;
    }

    .messsage-container#home-message {
        background-size: 100% auto;
    
    }

    .row-container#services-row {
        display: block;
        height:fit-content;
    }

    .row-container#gallery-row {
        height:95%;
    }

    .gallery-img-container {
        width: 14%;
    }

    .App-page#our-services {
        min-height:100%;
    }

    .App-page#gallery {
        min-height: fit-content;
        max-height:70vh;
    }

    .content-container#gallery-content {
        height: 100%;
    }

    .services-img-container {
        height: 45%;
        overflow:hidden;
        width:100%;
    }

    .services-gallery-img[data-id="trimmers"] {
        position: relative;
        bottom: 30%;
    }

    .services-p-container {
        max-width: 100%;
        height: 25%;
    }

    .services-gallery-msg {
        width:inherit;
        font-size:large;
    }

    ul#nav-bar li {
        margin:2vw;
    }


    .App-page#visit-us {
        min-height:100%;
        
    }

    div.hours {
        width:90%;
        height: fit-content;
    }

    img.map {
        transform: translate(0%, -45%);
        height:initial;
    }

    .map-container {
        height:23%;
        overflow: hidden;
        width: 100%;
    }

    div.contact-list {
        justify-content: unset;
    }

    p.contact-list {
        margin: 4vh;
        text-align: justify;
    }

    p.contact-text {
        display:none;
    }

    .contact-list#about {
        height: 100%;
        overflow-y:hidden;
    }

    .icon-social {
        height: 3em;
    }

    #social-links {
        margin: 0;
    }

    /* .contact-list li {
        display: flex;
        align-items: center;
        justify-content:center;
        flex-wrap: wrap;
    } */

    .contact-list#contact-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .contact-icon-responsive {
        height: 2.75em;
    }

    div.contact-info {
        width: 100%;
    }


    div.visit-us-msg {
        width: 95%;
        align-items: center;
    }
}