html {
  overflow:hidden;
}

ul {
  padding-inline-start: 0;
}

.App {
  background-image: url('./components/assets/bckgrd/chair_pink.png');
  background-size:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.App-content {
  display:flex;
  flex-direction: column;
  align-items:center;
  text-align: center;
  overflow:auto;
  height: 92vh;
  width:100vw;
  padding-top: 8vh;
}

.App-logo {
  height: 10vh;
  pointer-events: none;
}

.App-header {
  background-image: url('./components/assets/bckgrd/chair_pink.png');
  background-size: cover;
  min-height: 10vh;
  position:fixed;
  z-index: 1000;
}

.App-page {
  background-color: #282c34;
  min-width:80vw;
  max-width:80vw;
  max-height: 75vh;
  margin: 4vh 10vh 4vh 10vh;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow:5px 5px 10px #666;
}

.App-page#home {
  background: rgb(0,86,73);
  background: linear-gradient(180deg, rgba(0,86,73,1) 0%, rgba(9,137,131,0.6840861344537815) 35%, rgba(14,169,167,0.6840861344537815) 67%);
  margin-top:10vh;
}

.App-page#about {
    background : linear-gradient(111.23deg, rgba(245, 179, 0, 0.8) 0%, rgba(170, 142, 8, 0.8) 100%);
}

.App-page#gallery {
  background-color: rgba(0,0,0,0.7);
  min-height:110vh;
}

.App-page#our-services {
  background-color: rgba(0,0,0,0.75);
}

.App-page#visit-us {
  background-color: rgba(0,0,0,0.75);
}

.App-link {
  color: #61dafb;
}

button {
  background-color: rgba(168, 34, 94, 1);
  border:none;
  height:fit-content;
  width:30vw;
  height:3em;
  min-width:fit-content;
  margin-right: 15vh;
  border-radius:5px;
  font-size:medium;
  color:white;
   font-family: 'Backage-Regular', Arial, Helvetica, sans-serif;
   text-wrap: nowrap;
}

.button-container {
  display:flex;
  height:10vh;
  justify-content:flex-end;
  align-items:center;
  width:100vw;
}

.content-container {
  display:flex;
  width:100%;
}

.content-container[multirow="true"] {
  flex-direction: column;
  justify-content: center;
  
}

.content-container#about-content {
  position:relative;
}

.content-container#gallery-content {
  background-image: url('./components/assets/svg/leopard.svg');
  display:flex;
  align-content:center;
}

#gallery-row {
  display: flex;
  flex-wrap: wrap;
}
.gallery-gallery-img {
  height:auto;
  width:100%;
}

.gallery-img-container {
  flex-grow: 1;
  width: 18%;
  margin: 0vh 0.25vh 0vh 0.25vh;
}

.gallery-gallery-msg {
  height:100%;
  display:flex;
  align-items: center;
  margin:10%;
}

.gallery-p-container {
  font-family: 'Roboto Mono';
  font-size: 1.1em;
  min-width: 20%;
}

.services-gallery-img {
  width:100%;
}

.services-gallery-msg {
  display:flex;
  justify-content: center;
  align-items:center;
  color: white;
  text-decoration: none;
  font-family: 'Backage-Regular', Arial, Helvetica, sans-serif;
  width:100%;
}

a.icon-social {
  filter: drop-shadow(2px 4px 8px rgba(255,255,255,0.3));
  margin:1vh;
}

.icon {
  height:0.8em;
}



.contact-icon {
  height: 3em;
}

.contact-icon-responsive {
  height:1.5em;
}

.logo {
  height:20vh;
}

#logo-text {
  width:11.25em;
  margin-top:1vh;
}

.messsage-container {
  background-size:contain;
  background-repeat:no-repeat;
  background-size:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width:55%;
  min-width:55%;
}

.messsage-container#home-message {
  background-color:  rgba(245, 179, 0, 0.10);
  background-image: url('./components/assets/bckgrd/chair_bw.png');

}

#message-container-about {
  z-index: 1;
  justify-content:unset;
  height:100%;
  overflow: hidden;
  margin-right: 2%;
  margin-left: 3%;
}

.img-container {
  display:flex;
  justify-content:center;
  align-items:center;
  width:45%;
  overflow:hidden;
}

#img-container-about {
  z-index:1;
  align-items:baseline;
}

.main-img#about-img {
  height:100%;
  width:auto;

}

#img-container-home {
  align-items:flex-start;
}


.main-img {
  width:100%;
  filter: boxshadow(5px 5px 10px #666);
}

.main-img#about {
  width:107%;
}

a[active]    {
  text-decoration: underline;
  color: rgba(14,169,167, 1);
}


.hamburger {
  display:none;
}

#nav-bar {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-right: 10vh;
  min-width:100%;
}

.navigation-bar-container {
  display:flex;
  justify-content: right;
  background-color: black;
  width: 100%;
  box-shadow:2px 4px 8px rgba(0,0,0,0.4);
}

.navigation-bar{
  height:8vh;
  width:55%;
  color:white;
  font-size: medium;
  padding-right:20vh;
}

#nav-bar-toggle {
  display:none
}

.overlay {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
}

.overlay#about {
  background-image: url('./components/assets/svg/cheetah.svg');
  position:absolute;  opacity: 0.10;
  height:100%;
  width:100%;
}

.overlay#home {
  background: linear-gradient(180deg, rgba(0,86,73,1) 0%, rgba(9,137,131,0.6840861344537815) 35%, rgba(14,169,167,0.6840861344537815) 67%);
}

p {

  font-family: Roboto Mono;
  font-size: 0.7em;
}

.services-p-container {
  min-width:30%;
  max-width:30%;
  display:flex;
  justify-content: center;
}

.services-img-container {
  display:flex;
  justify-content:center;
  align-items:center;
  width:40%;
}

.row-container {
  display:flex;
  overflow:hidden;
}

.row-container#services-row {
  height:33.33%;
}

.row-container#gallery-row {
  height:fit-content;
  margin: 1vh;
}

.title {
  font-family: 'Backage-Regular', Arial, Helvetica, sans-serif;
  margin: 0% 50% 0% 50%;
  font-size:1em;
  white-space:nowrap;
  letter-spacing: 0.1em;
}

.title-container {
  width:100%;
}

a {
   color:white;
   text-decoration: none;
   font-family: 'Backage-Regular', Arial, Helvetica, sans-serif;
   text-wrap: nowrap;
}

a.navigation-link {
  scroll-behavior: smooth;
}

li.navigation-link {
  margin-left:2vh;
}

h2 {
  color:white;
  font-family: 'Backage-Regular', Arial;
  font-weight: lighter;
  letter-spacing: 0.1em;
  margin: 0.75em;
}

h2.title-black {
  color:black;
  scroll-margin-top:10rem;
}

h2.header-black {
  font-size:medium;
}

.title-black#home-page {
  opacity: 0%;
}

.contact-list li {
  list-style-type:none;
  font-family:'Roboto Mono';
  letter-spacing: 0.1em;
}

.contact-list a {
  list-style-type:none;
  font-family:'Roboto Mono';
  text-align:left;
  letter-spacing: 0.1em;
  text-decoration:underline;
  color:rgba(14,169,167);
  font-weight: 600;
}

div.contact-list {
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content: space-around;
  align-items: center;
} 

.contact-list#about {
  height: 80%;
  overflow-y: scroll;
}

ul.contact-list#about li {
  margin-bottom: 1em;
}

p.about-text {
  background-color: rgba(0, 0, 0, .9);
  padding:.05em;
}

div.hours#about {
  height: 45%
}

h2.title-black {
  color:black;
  font-size: large;
  font-weight: bold;
}

h2.section-title-black {
  color:black;
  font-size: medium;
  font-weight: bold;
}

div.visit-us-msg {
  width:60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


#visit-us-msg {
  font-size:medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
}

.map-container {
  height:100%;
  width:50%;
  overflow:hidden;
}

p.contact-list {
  font-family:'Roboto Mono';
  font-size:medium;
  letter-spacing: 0.1em;
  text-wrap:wrap;
}

.contact-list li {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
}


p.contact-text {
  margin: 0.25vh 0.5vh 0.25vh 0.75vh;
  font-size:medium;
}


.contact-list#social-links {
  display:flex;
  flex-direction:row;
  justify-content: space-evenly;
  width: 80%;
}

ul.social-links {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}
div.social-info {
  width:100%;
  display:flex;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}